import React from 'react'
import {Link} from 'react-router-dom'

export default function PopUp(props) {
  return props.trigger ? (
    <ul className='md:hidden flex-col absolute mt-8 space-y-4 bg-slate-100 w-[85%] p-5 rounded-md flex text-black'>
        <li onClick={props.triggerFunc} className='cursor-pointer hover:underline'>
            <Link to="/">Home</Link>
        </li>
        <li onClick={props.triggerFunc} className='cursor-pointer hover:underline'>
            <Link to="musicvideos">Music Videos</Link>
        </li>
        <li onClick={props.triggerFunc} className='cursor-pointer hover:underline'>
            <Link to="commercials">Commercials</Link>
        </li>
        <li onClick={props.triggerFunc} className='cursor-pointer hover:underline'>
            <Link to="bio">Bio</Link>
        </li>
        <a href="mailto:twitch@polarfilmsltd.com">
            <li onClick={props.triggerFunc} className='cursor-pointer hover:underline'>Contact</li>
        </a>
    </ul>
  ) : null
}

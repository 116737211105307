import {useState, useEffect} from 'react'
import '../App.css'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'

export default function Home() {
    
    const [opacity, setOpacity] = useState('opacity-100 h-screen')
    const [trigger, setTrigger] = useState(true)
    const [font, setFont] = useState('opacity-100')

    // preloader function
    useEffect(()=>{
        setTimeout(()=>{
            setOpacity('h-0')
        }, 1000)

        setTimeout(()=>{
            setFont('opacity-0')
        }, 800)

        setTimeout(()=>{
            setOpacity('opacity-0')
        }, 1300)

        setTimeout(()=>{
            setTrigger(false)
        }, 1520)
    },[])

  return (
    <div className="h-screen flex items-end justify-center w-full background pb-20">
        <Loader
            color="bg-black "
            fontColor="text-white text-[3.5rem] md:text-[8rem]"
            opacity={opacity}
            trigger={trigger}
            font={font}
            text={'WELCOME'} />
        <div className='pb-[5rem] md:pb-0 flex w-fit text-white pl-10'>
            <a href="/musicvideos">
                <button className='border border-white rounded-xl py-3 px-10 hover:bg-white hover:bg-opacity-20'>
                    <p>Enter Site</p>
                </button>
            </a>
        </div>
    </div>
  )
}

import {React, useEffect, useState} from 'react'
import dammy from '../assets/dammybio.png'
// import Press from '../components/Press'
import Footer from '../components/Footer'
import Loader from '../components/Loader'


export default function Bio() {
    // preloader function
    const [opacity, setOpacity] = useState('opacity-100 h-screen')
    const [triggerLoader, setTriggerLoader] = useState(true)
    const [font, setFont] = useState('opacity-100')
     
    useEffect(()=>{
        setTimeout(()=>{
            setOpacity('h-0')
        }, 500)

        setTimeout(()=>{
            setFont('opacity-0')
        }, 800)

        setTimeout(()=>{
            setOpacity('opacity-0')
        }, 800)

        setTimeout(()=>{
            setTriggerLoader(false)
        }, 1000)
    },[])


    // preloader function
    const [opacityTwo, setOpacityTwo] = useState('opacity-100 h-screen')
    const [triggerLoaderTwo, setTriggerLoaderTwo] = useState(true)
    const [fontTwo, setFontTwo] = useState('opacity-100')
     
    useEffect(()=>{
        setTimeout(()=>{
            setOpacityTwo('h-0')
        }, 300)

        setTimeout(()=>{
            setFontTwo('opacity-0')
        }, 800)

        setTimeout(()=>{
            setOpacityTwo('opacity-0')
        }, 600)

        setTimeout(()=>{
            setTriggerLoaderTwo(false)
        }, 800)
    },[])
    
  return (
    <div className='pt-60 w-screen overflow-hidden'>
        <Loader
            color="bg-black"
            fontColor="text-[2rem] md:text-[2.1rem]"
            opacity={opacity}
            trigger={triggerLoader}
            font={font}
            // text="Loading"
            // img={loaderImg}
            />
        <Loader
            color="bg-[#161616]"
            fontColor="text-[2rem] md:text-[2.1rem]"
            opacity={opacityTwo}
            trigger={triggerLoaderTwo}
            font={fontTwo}
            // text="Loadr"
            // img={loaderImg}
            />
        <div className='px-7 lg:px-0 lg:w-2/6 lg:ml-48'>
            <h2 className='text-3xl font-bold'>Apampa Oluwadamilola Owolabi PKA Dammy Twitch (born October 27, 1995)</h2>
            <p className='hidden lg:block mt-6 text-lg text-gray-500'>is an African film director and photographer. Dammy Twitch is known to have directed videos for some of the top musicians and brands all over the world and has strongly established himself among the recognised video directors in Africa.</p>
            <p className='lg:hidden mt-6 text-lg text-gray-500'>is an African film director and photographer. Dammy Twitch is known to have directed videos for some of the top musicians and brands all over the world and has strongly established himself among the recognised video directors in Africa.</p>
        </div>
        <div className='flex flex-col-reverse 2xl:flex-row mt-20 lg:mt-32 px-7 lg:px-48 items-end'>
            <div className='lg:pr-16'>
                <p>
                Dammy Twitch is the founder of Polar Films Production and Kadiri Studios, production companies that have worked with the top brands across the world like Google, Infinix, Tecno Mobile, Nestle, Bet9ja, 1xBet, Jimmy Fallon, Oppo, Coca-cola and so on. He has also directed music videos for top artists like Davido, Mayorkun, Falz, Omah Lay, Zlatan, Da Baby, Yung Bleu, Simi, and so on. 
                </p>
                <p className='mt-5'>
                Dammy Twitch has directed his first film titled I hate it here (2023), a deeply personal film that explores the devastating impact of domestic violence on a family, and the lasting scars it leaves on a child’s soul.
                </p>
                <p className='mt-5'>
                In 2022, Dammy Twitch was listed on Forbes 30 under 30 as one of the incredible young individuals in Africa who continue to inspire, support and contribute to his generation and more to come.
                </p>
            </div>
            <img className='mb-7 w-full 2xl:mb-0' src={dammy} alt="" />
        </div>
        {/* <div className='mb-20 px-7 mt-20 lg:mt-40 flex flex-col space-y-7'>
            <h1 className='text-xl font-bold'>Press</h1>
            <div className='border-b border-black'></div>
            <Press
                title="How Dammy Twitch, Medlin Boss and Tayo Aina are going beyond the extraordinary"
                year="2021"
                media="Pulse NG"
                link="https://www.pulse.ng/entertainment/celebrities/how-dammy-twitch-medlin-boss-and-tayo-aina-are-going-beyond-the-extraordinary/1fjy241" />
            <div className='border-b border-black'></div>
            <Press
                title="INTERVIEW: How I started shooting videos for Davido, Burna Boy, Falz, others"
                year="2019"
                media="Premium Times"
                link="https://www.premiumtimesng.com/entertainment/music/361815-interview-how-i-started-shooting-videos-for-davido-burna-boy-falz-others-dammy-twitch.html?tztc=1" />
            <div className='border-b border-black'></div>
        </div> */}
        <Footer />
    </div>
  )
}

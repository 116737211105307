export default function Loader(props) {
    return props.trigger ? (
    <div className={`z-10 top-0 w-screen ${props.opacity} opacity absolute ${props.color} transition-all delay-150 duration-300 ease-in-out flex flex-col items-center justify-center px-20`}>
        <div className={`flex flex-col items-center justify-center w-fit`}>
          <h1 className={`${props.font} ${props.fontColor} transition-all delay-150 z-50 duration-300 ease-in-out leading-[3.25rem] md:leading-[7.5rem] font-black`}>{props.text}</h1>
          <img className={`w-64 -z-20 mt-[-6rem] md:mt-[-9rem] ${props.font} ${props.fontColor}`} src={props.img} alt="" />
        </div> 
    </div>
  ) : null
}

import './App.css';
import Header from './components/Header';
import Bio from './pages/Bio';
import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom'
import MusicVideos from './pages/MusicVideos';
import Commercials from './pages/Commercials';
import logoWhite from "./assets/logo-white.png"

function App() {
  return (
    <div className="App">
      <Header logo={logoWhite} font="text-white" />
      <Routes>
        <Route path="/" element={
          <div>
            <Home />
          </div>} />
        <Route path="musicvideos" element={<MusicVideos />} />
        <Route path="bio" element={<Bio />} />
        <Route path="commercials" element={<Commercials />} />
      </Routes>
    </div>
  );
}

export default App;

import {React, useEffect, useState} from 'react'
import Videos from '../components/Videos'
import axios from 'axios';
import '../App.css'
import Footer from '../components/Footer';
import VidPlayer from '../components/VidPlayer';
// import DammyGif from '../assets/dammyplay.gif'
// import loaderImg from "../assets/damyldr.gif"
import Loader from '../components/Loader';



export default function Commercials() {
    const [vidInfo, setVidInfo] = useState([])
    const [embed, setEmbed] = useState()
    const [trigger, setTrigger] = useState(false)

    // api call function
    const fetchVimeoData = async () => {
        const cachedResponse = localStorage.getItem("comm_videos");

        if(cachedResponse) {
            return JSON.parse(cachedResponse);
        } else {
            const response = await axios.get('https://api.vimeo.com/channels/1835023/videos?direction=desc&per_page=98&page=1', {
                headers: {
                    Authorization: 'Bearer 14cde2cea608746c0e1ab89a8dcce8bc'
                },
            });
            const jsonResponse = await response;
            localStorage.setItem("comm_videos", JSON.stringify(jsonResponse));

            return jsonResponse
        }
    }

    // update cache in background
    useEffect(() => {
        const cachedResponse = localStorage.getItem("comm_videos");
        if(cachedResponse){
            const res = JSON.parse(cachedResponse);
            const vals = Object.values(res.headers)

            axios.get('https://api.vimeo.com/channels/1835023/videos?direction=desc&per_page=98&page=1', {
                headers: {
                    Authorization: 'Bearer 14cde2cea608746c0e1ab89a8dcce8bc'
                },
            })
            .then(res => {
                const modDate_Vimeo = res.headers.get("last-modified")

                if(modDate_Vimeo !== vals[3]) {
                    localStorage.setItem("comm_videos", JSON.stringify(res));
                }
            })
        }
    }, [])


    // final api call
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchVimeoData()
            setVidInfo(response.data.data)
        }

        fetchData()

    }, [])


    // handle popup
    function handlePopup() {
        setTrigger(false)
    }

    // // handle video popup
    function handleVidPopup(data) {
        setEmbed(data)
        setTrigger(true)
    }

    

    // Create Video function
    function createVideo(vids) {
        return <Videos 
                    onClick={() => handleVidPopup(vids.player_embed_url)}
                    key={vids.link} 
                    name={vids.name} 
                    image={vids.pictures.sizes[4].link} />
    }



    // preloader function
    const [opacity, setOpacity] = useState('opacity-100 h-screen')
    const [triggerLoader, setTriggerLoader] = useState(true)
    const [font, setFont] = useState('opacity-100')
     useEffect(()=>{
        if(vidInfo.length > 1) {
        setTimeout(()=>{
            setOpacity('h-0')
        }, 500)

        setTimeout(()=>{
            setFont('opacity-0')
        }, 800)

        setTimeout(()=>{
            setOpacity('opacity-0')
        }, 800)

        setTimeout(()=>{
            setTriggerLoader(false)
        }, 1000)
    }
    },[vidInfo])


    // preloader function
    const [opacityTwo, setOpacityTwo] = useState('opacity-100 h-screen')
    const [triggerLoaderTwo, setTriggerLoaderTwo] = useState(true)
    const [fontTwo, setFontTwo] = useState('opacity-100')
     
    useEffect(()=>{
        if(vidInfo.length > 1) {
        setTimeout(()=>{
            setOpacityTwo('h-0')
        }, 300)

        setTimeout(()=>{
            setFontTwo('opacity-0')
        }, 800)

        setTimeout(()=>{
            setOpacityTwo('opacity-0')
        }, 600)

        setTimeout(()=>{
            setTriggerLoaderTwo(false)
        }, 800)
    }
    },[vidInfo])


  return (
    <div>
        <Loader
            color="bg-black"
            fontColor="text-[2rem] md:text-[2.1rem]"
            opacity={opacity}
            trigger={triggerLoader}
            font={font}
            />
        <Loader
            color="bg-[#161616]"
            fontColor="text-[2rem] md:text-[2.1rem]"
            opacity={opacityTwo}
            trigger={triggerLoaderTwo}
            font={fontTwo}
            />

        <VidPlayer
            click={handlePopup}
            trigger={trigger} >
            <div className='w-[100%] h-[100%] px-5 md:px-0'>
                <iframe className='w-[100%] h-[100%]' src={`${embed}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=263578`}  title="PATORANKING FT. DIAMOND PLATNUMZ - KOLO KOLO"></iframe>
            </div>
        </VidPlayer>
        <div className='pt-36 px-0 lg:px-5 flex flex-wrap justify-between items-center'>
        {/* <Videos
            image={vidInfo.image}
            name={vidInfo.name} /> */}
        {vidInfo ? vidInfo.map(createVideo) : null}
        <div className="lg:w-2/6 px-7 mb-7 lg:h-60" />
        </div>
        <Footer />
    </div>
  )
}

import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import {BsChevronDown} from "react-icons/bs"
import PopUp from './PopUp'

export default function Header(props) {
    const [trigger, setTrigger] = useState(false)

    function handleTrigger() {
        if(!trigger) {
            setTrigger(true)
        }else{
            setTrigger(false)
        }
            
        
    }
  return (
    <div className={`px-7 mt-20 flex flex-row z-30 justify-between w-full absolute ${props.font}`}>
        <div className='w-[50%] lg:w-[15%] cursor-pointer'>
            <Link to="/">
                <img src={props.logo} alt="dammy-twitch logo" />
            </Link>
        </div>
        <ul className='md:flex space-x-7 hidden'>
            <li className='cursor-pointer hover:underline'>
                <Link to="/">Home</Link>
            </li>
            <li className='cursor-pointer hover:underline'>
                <Link to="musicvideos">Music Videos</Link>
            </li>
            <li className='cursor-pointer hover:underline'>
                <Link to="commercials">Commercials</Link>
            </li>
            <li className='cursor-pointer hover:underline'>
                <Link to="bio">Bio</Link>
            </li>
            <a href="mailto:twitch@polarfilmsltd.com">
                <li className='cursor-pointer hover:underline'>Contact</li>
            </a>
        </ul>
        <div onClick={handleTrigger} className='md:hidden flex'>
            <BsChevronDown size={23}/>
        </div>
        <PopUp triggerFunc={handleTrigger} trigger={trigger} />

    </div>
  )
}

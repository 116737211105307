import React from 'react'
import SocialButtons from './SocialButtons'

export default function Footer() {
  return (
    <div className='text-sm md:text-base justify-center items-center space-y-3 lg:space-y-0 px-7 mt-20 mb-5 flex flex-col lg:flex-row md:justify-between'>
        <p>DAMMY TWITCH ⓒ 2023</p>
        <div className='flex flex-row space-x-3'>
            <SocialButtons
                name="Instagram"
                link="https://www.instagram.com/dammytwitch/" />
            <SocialButtons
                name="Twitter"
                link="https://twitter.com/DammyTwitch" />
            <SocialButtons
                name="Vimeo"
                link="https://vimeo.com/user87167374" />
            <SocialButtons
                name="Youtube"
                link="https://www.youtube.com/@TwitchBTS" />
        </div>
        <p>DESIGN AND DEV BY <a href="https://ediale.xyz" target="_blank" rel="noopener noreferrer">CLARENCE</a> </p>
    </div>
  )
}

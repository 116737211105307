import React from 'react'
// import img from '../assets/Rectangle 1.png'


export default function Videos(props) {
  return (
    <div data-scroll onClick={props.onClick} className='w-full hover:underline transition delay-150 duration-300 ease-in-out lg:w-2/6 px-7 lg:px-1 mb-7 cursor-pointer'>
        <div className='lg:h-60 xl:h-72 overflow-hidden'>
            <img className='w-full h-[80%] object-cover hover:opacity-60' src={props.image} alt="" />
            <p className='mt-3'>{props.name}</p>
        </div>
    </div>
  )
}

import React from 'react'
import { MdOutlineClose } from "react-icons/md";

export default function VidPlayer(props) {
  return props.trigger ? (
    <div onClick={props.click} className='h-screen md:px-10 lg:p-40 w-screen bg-black bg-opacity-80 flex flex-col items-center justify-center fixed z-40'>
        <div className='w-full flex items-end mt-32 mb-[-13rem] md:mt-0 md:mb-0 justify-end text-4xl md:text-2xl px-2 md:px-0 z-[10000]' onClick={props.click}>
            <MdOutlineClose className='cursor-pointer' color='white' onClick={props.click}/>
        </div>
        {props.children}
    </div>
  ) : (null);
}
